
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { auth } from '@/store/namespaces';

@Component({
    components: {
        SvgIcon,
    },
})
export default class UserBlocked extends Vue {

    get atcPhoneNoPlus(){
        return this.atcPhone.slice(1,this.atcPhone.length)
    }

    @auth.Getter(AuthMethods.getters.GET_ATC_PHONE)
     atcPhone!: string;
}
