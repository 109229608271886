import { render, staticRenderFns } from "./UserBlocked.vue?vue&type=template&id=6eb2b29e&scoped=true&"
import script from "./UserBlocked.vue?vue&type=script&lang=ts&"
export * from "./UserBlocked.vue?vue&type=script&lang=ts&"
import style0 from "./UserBlocked.vue?vue&type=style&index=0&id=6eb2b29e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb2b29e",
  null
  
)

export default component.exports